import * as React from "react";
import Layout from "../components/Layout";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import SEO from "../components/seo";

// markup
const ContactPage = () => {
  const props = {
    seoTitle: "Managed Services",
    seoDescription:
      "Accelerate your business with Strategix Technology Solutions.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        {/* <section>
          <div className="hero" id="contact">
            <div className="flex-col-c">
              <h2>Privacy</h2>
            </div>
          </div>
        </section> */}

        {/* Contact */}
        <section className="half privacy" style={{}}>
          <h1>Disclaimer: Agreement for Data Gathering and Processing</h1>

          <p>
            This platform is crafted to generate a vulnerability report based on
            information you supply. By utilising this platform and submitting
            your data, you acknowledge and assent to the following:
          </p>

          <h2>Consent to Gather Data:</h2>
          <p>
            You agree to the gathering of personal information—including your
            name, company name, email address, and phone number—necessary for
            the creation of the vulnerability report. This data gathering aligns
            with the Protection of Personal Information Act (POPIA) in South
            Africa and the European Union's General Data Protection Regulation
            (GDPR).
          </p>

          <h2>Data Management:</h2>
          <p>
            You comprehend and agree that Strategix Group will manage the
            accumulated data exclusively for the creation of the vulnerability
            report—organising and retaining information such as versions, your
            company name, email address, and phone number. We will not keep any
            excessive information, such as IP addresses or technological
            specifics.
          </p>

          <h2>Data Retention:</h2>
          <p>
            You permit Strategix Group to retain crucial data previously noted
            for a reasonable duration to fulfil its intended purpose. Following
            this period, we will safely dispose of or anonymise your data in
            compliance with pertinent data protection laws.
          </p>

          <h2>Disclosure of Information:</h2>
          <p>
            We will never reveal your personal information to third parties
            unless legally obligated or with your explicit permission. Your data
            will be treated with utmost confidentiality and security.
          </p>

          <h2>Communication Permission:</h2>
          <p>
            You hereby permit Strategix Group to interact with you regarding
            matters related to the vulnerability report, such as providing
            recommendations, updates, addressing inquiries, and soliciting
            feedback. We will not employ your contact details for any other aim
            without your explicit permission.
          </p>

          <h2>Data Safety:</h2>
          <p>
            We are dedicated to executing plausible security measures to
            safeguard your data from unauthorised access, exposure, alteration,
            or destruction. The security of your data is a primary concern for
            us.
          </p>

          <h2>User Privileges:</h2>
          <p>
            You reserve the right to demand access to, modification of, or
            deletion of your personal information in compliance with POPIA and
            GDPR regulations. Should you need to exercise these rights or if you
            have questions regarding your data, please reach out to us via the
            contact information provided.
          </p>

          <h2>Opt-Out Policy:</h2>
          <p>
            You hold the right to refrain from providing specific information or
            to opt-out from data gathering. However, be aware that this may
            compromise our capability to deliver the desired vulnerability
            report.
          </p>

          <p>
            By utilising this platform and agreeing to this disclaimer, you
            confirm that you have read, comprehended, and agreed to the data
            gathering, processing, retention, and communication permissions
            concerning your personal information as described above, in
            accordance with POPIA and GDPR.
          </p>
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;
